/* .errorbarDefault {
  width: 100%;
  height: 60px;
  opacity:0;
  display:block;
  background-color:#CC0000;
  z-index:10;
  position:absolute;
  top:0px;
  padding:20px 20px 0px 80px;
  font-size:14px;
  font-weight:bold;
  color: white;
} */

/* .successbarDefault {
  width: 100%;
  height: 60px;
  opacity:0;
  display:block;
  background-color:green;
  z-index:10;
  position:absolute;
  top:0px;
  padding:20px 20px 0px 80px;
  font-size:14px;
  font-weight:bold;
  color: white;
} */

.errorbarDefault {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  /* width: 100%; */
  min-width: 380px;
  height: 80px;
  opacity: 0;
  display: none;
  background-color: white;
  z-index: 999999;
  position: absolute;
  top: 5px;
  padding: 20px;
  font-size: 12px;
  /* font-weight: bold; */
  /*color: #CC0000;
  border: 2px #CC0000 solid;*/
  /* padding: 10px; */
  right: 10px;
  /* border-radius: 10px; */
  border-radius: 4px;
  -webkit-box-shadow: 0 4px 12px rgba(0,0,0,0.15);
  box-shadow: 0 4px 12px rgba(0,0,0,0.15);
  z-index: 9999;
  color: rgba(0,0,0,0.65);
}

.notiftitle{
  font-size:14px;
  font-weight:bold;
  margin-bottom: 5px;
}

.notiftitle .errorIcon{
  color: red;
}

.notiftitle .successIcon{
  color: green;
}

.successbarDefault {
  /* width: 100%; */
  min-width: 380px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  height: 80px;
  opacity: 0;
  display: none;
  background-color: white;
  z-index: 999999;
  position: absolute;
  top: 5px;
  padding: 20px;
  font-size: 12px;
  /* font-weight: bold; */
  color: green;
  /* border: 2px green solid; */
  /* padding: 10px; */
  right: 10px;
  border-radius: 4px;
  -webkit-box-shadow: 0 4px 12px rgba(0,0,0,0.15);
  box-shadow: 0 4px 12px rgba(0,0,0,0.15);
  z-index: 9999;
  color: rgba(0,0,0,0.65);
}

@keyframes slideErrorIn {
  from {
    right: -50px;
    opacity: 0;
  }

  to {
    right: 20px;
    opacity: 1;
  }
}

.slideErrorIn {
  animation: slideErrorIn 0.3s forwards;
  -webkit-animation: slideErrorIn 0.3s forwards;
  display: block;
}


@keyframes slideErrorOut {
  from {
    right: 20px;
    opacity: 1;
  }

  to {
    opacity: 0;
    right: -50px;
  }
}

.slideErrorOut {
  animation: slideErrorOut 0.3s forwards;
  -webkit-animation: slideErrorOut 0.3s forwards;
  display: none;
}
